import React, { useState } from 'react';

const Credits = () => {
  return (<>

    <div className=' flex justify-around  items-center min-h-screen bg-slate-700'>

      <div className=" relative w-[350px] bg-common_text h-48  rounded-lg shadow-lg hover:h-[29rem] hover:w-[35rem] duration-700">

        <div className=' absolute w-40 h-40 overflow-hidden bg-white left-1/2 translate-x-[-50%] top-[-50px]  rounded-3xl shadow-2xl'>
          <img src={process.env.PUBLIC_URL + '/Light_yagami.jpg'} alt="Light" />
        </div>

        <div className=' absolute w-full overflow-hidden h-full flex justify-center items-end mt-6'>
          <div className=' p-9 overflow-hidden text-center w-full duration-500 translate-y-[340px] hover:translate-y-[0px]'>
            <h2 className=' font-extrabold text-xl text-slate-700'>Atharva Ghanvat <br /> 
            <span className=' text-base text-gray-950 opacity-90' > Supreme Frontend Developer</span>  
            </h2>
            <p className=' text-md text-slate-700  font-bold mt-2'>I am a Frontend Developer with 3 years of experience in building websites and web applications. I specialize in JavaScript and have professional experience working with React and Angular.</p>
              <div className='mt-4'>
                <h3 className='text-lg font-semibold'>Experience</h3>
                <div className='flex items-center mt-2'>
                  <div className='w-1/4'>
                    <p className='text-sm'>LeetCode</p>
                  </div>
                  <div className='w-3/4'>
                    <div className='bg-gray-200 h-2 rounded'>
                      <div className='bg-green-500 h-full' style={{ width: '75%' }}></div>
                    </div>
                  </div>
                </div>
                <div className='flex items-center mt-2'>
                  <div className='w-1/4'>
                    <p className='text-sm'>HackerRank</p>
                  </div>
                  <div className='w-3/4'>
                    <div className='bg-gray-200 h-2 rounded'>
                      <div className='bg-blue-500 h-full' style={{ width: '60%' }}></div>
                    </div>
                  </div>
                </div>
                <div className='flex items-center mt-2'>
                  <div className='w-1/4'>
                    <p className='text-sm'>Codeforces</p>
                  </div>
                  <div className='w-3/4'>
                    <div className='bg-gray-200 h-2 rounded'>
                      <div className='bg-yellow-500 h-full' style={{ width: '80%' }}></div>
                    </div>
                  </div>
                </div>
              </div>
            <button className=' bg-slate-700 text-white px-4 py-2 rounded-lg mt-4'>Contact</button>
          </div>
        </div>
      </div>


 <div className=" relative w-[350px] h-48 bg-common_text rounded-lg shadow-lg hover:h-[29rem] hover:w-[35rem] duration-700">

<div className=' absolute w-40 h-40 overflow-hidden left-1/2 translate-x-[-50%] top-[-50px]  rounded-3xl shadow-2xl'>
  <img src={process.env.PUBLIC_URL + '/Hideka_ryuzaki.jpeg'} alt="Light" />
</div>

<div className=' absolute w-full overflow-hidden h-full flex justify-center items-end mt-6 '>
  <div className=' p-9 overflow-hidden text-center w-full duration-500 translate-y-[360px]  hover:translate-y-[0px]'>
    <h2 className=' font-extrabold text-xl text-slate-700'>Anup Mishra <br /> 
    <span className=' text-base text-black opacity-90' >Noob Backend Developer</span>  
    </h2>
    <p className=' text-md text-slate-700 mt-2'>I am a Backend Developer currently fresher with  experience in college projects for building complex website and web applications Logic. I specialize in Security architecture and have professional experience working with Node JS  and Other backend Libraries.</p>




      <div className='mt-4'>
        <h3 className='text-lg font-semibold'>Experience</h3>
        <div className='flex items-center mt-2'>
          <div className='w-1/4'>
            <p className='text-sm'>LeetCode</p>
          </div>
          <div className='w-3/4'>
            <div className='bg-gray-200 h-2 rounded'>
              <div className='bg-green-500 h-full' style={{ width: '65%' }}></div>
            </div>
          </div>
        </div>
        <div className='flex items-center mt-2'>
          <div className='w-1/4'>
            <p className='text-sm'>HackerRank</p>
          </div>
          <div className='w-3/4'>
            <div className='bg-gray-200 h-2 rounded'>
              <div className='bg-blue-500 h-full' style={{ width: '85%' }}></div>
            </div>
          </div>
        </div>
        <div className='flex items-center mt-2'>
          <div className='w-1/4'>
            <p className='text-sm'>Codeforces</p>
          </div>
          <div className='w-3/4'>
            <div className='bg-gray-200 h-2 rounded'>
              <div className='bg-yellow-500 h-full' style={{ width: '80%' }}></div>
            </div>
          </div>
        </div>
      </div>
    <button className=' bg-slate-700 text-white px-4 py-2 rounded-lg mt-4'>Contact</button>


  
  </div>
</div>


</div>









    </div>

  </>)

}
export default Credits
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './userDashboard.css';

const Tabs = () => {
  const [currentTab, setCurrentTab] = useState('1');
  const tabs = [
    {
      id: 1,
      tabTitle: 'Service 1',
      title: 'Temporary Share',
      content: 'Temporary Share allows you to securely share files for a limited duration. With this feature, you can create temporary links to share files with others, providing access for a specified period. This is ideal for sharing sensitive or time-sensitive information without granting permanent access. Once the specified time period expires, the shared links automatically become inaccessible, ensuring your data remains protected.',
      button: <Link to="/uploader" className="game-button">Use Now</Link>,
    },
    {
      id: 2,
      tabTitle: 'Service 2',
      title: 'Permanent Share',
      content: "Permanent Upload offers a reliable solution for storing and sharing files indefinitely. With this feature, you can securely upload files to our platform, ensuring long-term accessibility and availability. Whether it's important documents, media files, or archives, Permanent Upload provides a convenient way to store and share your data with others. Once uploaded, your files remain accessible to you and authorized users, offering a reliable solution for storing and managing your digital assets.",
      button: <Link to="/uploadtodo" className="game-button">Use Now</Link>,
    },
    {
      id: 3,
      tabTitle: 'Service 3',
      title: 'Peer to Peer',
      content: "Peer to Peer Share empowers you to directly share files with other users on our platform. By utilizing peer-to-peer technology, this feature enables fast and efficient file transfers between users, without the need for intermediate servers. Whether it's large documents, multimedia files, or collaborative projects, Peer to Peer Share facilitates seamless sharing between peers, enhancing collaboration and productivity. Enjoy secure and direct file exchanges with colleagues, clients, or collaborators, ensuring swift and reliable data transmission.",
      button: <Link to="/p2psender" className="game-button">Use Now</Link>,
    },
  ];

  const handleTabClick = (e) => {
    setCurrentTab(e.target.id);
  };

  return (
    <div className="game-container">
      <div className="tab-container">
        {tabs.map((tab, i) => (
          <button
            key={i}
            id={tab.id}
            disabled={currentTab === `${tab.id}`}
            onClick={handleTabClick}
            className={`tab-button ${currentTab === `${tab.id}` ? 'active' : ''}`}
          >
            {tab.tabTitle}
          </button>
        ))}
      </div>
      <div className="content-container">
        {tabs.map((tab, i) => (
          <div key={i} className={`content ${currentTab === `${tab.id}` ? 'active' : ''}`}>
            <div className="content-header">
              <h2>{tab.title}</h2>
            </div>
            <div className="content-body">
              <p>{tab.content}</p>
              <div className="button-container">{tab.button}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Tabs;
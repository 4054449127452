import React, { useState,useRef } from 'react';
import Styles from './ggwp.module.css';
import axios from 'axios';
import JSZip from  "jszip";
import { toast } from 'react-toastify';
import ModalUpgrade from  '../../moadl/upgrade'
import { urlforback } from '../../url';
function Pritempuploader() {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [progress, setProgress] = useState(0);
  const [showprog,setprog] =  useState(false)
  const [IsOpen, setIsOpen] = useState(false)
  const [url ,seturl] = useState('')
const [expirtime,setexpirtime] = useState('1')

const zipfile = async () => {
  
  if (selectedFiles.length === 0) {
    return null;
  }

  const zip = new JSZip();

  for (const file of selectedFiles) {
    checkfilesize()
    const fileBlob = await file.arrayBuffer();
    zip.file(file.name, fileBlob);
  }

  return zip.generateAsync({ type: "blob" });
};



  const handleFileChange = (event) => {
    setSelectedFiles(prevFiles => [...prevFiles, ...Array.from(event.target.files)]);
  };

  const textAreaRef = useRef(null);

  const copyToClipboard = () => {
    if (textAreaRef.current) {
      textAreaRef.current.select();
      document.execCommand('copy');
      toast.success("Link copied successfully")
    }
  }

  const checkfilesize = async () => {
    for (const file of selectedFiles) {
      if (file.size > 104857600) {
        setIsOpen(true)
        return false;
      }
    }
    return true;
  };

  


  const handleDrop = (event) => {
    event.preventDefault();
    setSelectedFiles(prevFiles => [...prevFiles, ...Array.from(event.dataTransfer.files)]);
  };

  const handleRemoveFile = (index) => {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);
  };

const refresh = ()=>{
  setSelectedFiles([])
  seturl("")
}



const handleUpload = async () => {
  console.log(expirtime);
  if (!selectedFiles) return alert("No files selected");
  if (selectedFiles.length > 0) {
    setprog(true);
    setProgress(0);
    try {
      const zipBlob = await zipfile();
      const fd = new FormData();
      fd.append('expirtime', expirtime);
      fd.append('files', zipBlob, Date.now()+'.zip');
      toast.info("uploading");
      let posturl;
      if (expirtime === '1') posturl = "https://tmpfiles.org/api/v1/upload/";
      else posturl = `${urlforback}api/tempupload`;
      if (posturl == null) return toast("Error Occurred", { type: "error" });
      console.log(posturl);
      await axios.post(posturl, fd, {
        onUploadProgress: (progressevent) => {
          console.log(progressevent.progress * 100);
          const progresscom = Math.round((progressevent.loaded / progressevent.total) * 100);
          setProgress(progresscom);
        }
      }).then((r) => {
        console.log(r);
        toast.success("File Uploaded");
        console.log("Sucessfully uploaded", r.data.data.url);
        if ((r.data.status === "success" && expirtime === '1')) {
          console.log("Redirecting");
          const url2 = r.data.data.url;
          const parts = url2.split('/');
          const newurl = parts.slice(0, 3).join('/') + '/dl/' + parts.slice(3).join('/');
          seturl(newurl);
          console.log(newurl);
        } else {
          seturl(r.data.data.url);
        }
        setprog(false);
        setSelectedFiles([]);
      }).catch((e) => {
        console.error("Error uploading ", e);
        setprog(false);
      });
    } catch (e) {
      console.log(e);
      alert("An error occured while zipping the file");
    }
  }
};





 const clearque = () =>{
    setSelectedFiles([])
    setprog(false)}
  return (<>
  <ModalUpgrade isOpen={false} setIsOpen={setIsOpen} />
   <div style={{overflow:"hidden"}}>
  <div className={Styles.backgp}>
      <div className={Styles.flexContainer}>
        <div className={Styles.uploaderContainer}>
          
      <div
        className={Styles.filePicker}
        onDrop={handleDrop}
        onDragOver={(event) => event.preventDefault()}
      >
        <input
          id="fileInput"
          type="file"
          onChange={handleFileChange}
          name="tempfiler"
          multiple
          style={{ display: 'none' }}
        />
        <p style={{fontSize:"1.4rem",color:"#ffff00",fontFamily:"fantasy",userSelect:"none"}}>
          Drag and drop files here or{' '}
          <label htmlFor="fileInput" className={Styles.fileInputLabel}>
            click to select files
          </label>
        </p>
      </div>
      
      {showprog && 
    <progress value={progress} max="100" className={Styles.progress} />
      }
      {selectedFiles.length > 0 && (
        <div className={Styles.fileList}>
          <h3 className=' font-extrabold text-center text-yellow-300'>Selected Files:</h3>
          <ul>
            {selectedFiles.map((file, index) => (
              <li key={index}>
                {file.name}
                <button onClick={() => handleRemoveFile(index)}>Remove</button>
              </li>
            ))}
          </ul>
          <label htmlFor="expiretime" className='text-red-600 font-extrabold text-2xl'>Select expiry time</label><br/>
            <select name='expiretime' value={expirtime} onChange={(e)=>setexpirtime(e.target.value)}  className='text-black font-black' >
            <option value='1'>1 Hour </option>
            <option value='5'>5 Hour </option>
            <option value='12'>12 Hour </option>
            <option value='24'>24 Hour </option>
          </select>
          <button id='uploader' className={Styles.uploadButton} onClick={handleUpload}>
        Upload
      </button>

      <button className={Styles.clearButton}  onClick={clearque}>
        Clear All
      </button>
        </div>
        
      )}
      {/* here a url will be generated giveing that it terminal like look and click to copy */}
      
      {url !== '' ? (<>
              <div className={Styles.resultBox}>
          <textarea className={Styles.textarea} readOnly value={url} ref={textAreaRef}></textarea>
          <button className={Styles.button} onClick={copyToClipboard}>Copy Url</button>
        </div>
<div><button className="text-center w-auto font-bold float-end text-green-400 hover:text-blue-100" onClick={refresh} >Use again?</button></div>
        </>
   
      ) : null}
    </div>
   </div>
    </div>
    </div>
    </>
  );
}

export default Pritempuploader;
import React, { useState,useRef } from 'react';
import Styles from './uploader.module.css';
import axios from 'axios';
import { toast } from 'react-toastify';
import Alert from '@mui/material/Alert';
import JSZip from  "jszip";
import ModalUpgrade from  '../../moadl/upgrade'

function UploaderD() {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [progress, setProgress] = useState(0);
  const [showprog,setprog] =  useState(false)
  const [url ,seturl] = useState('')
  const [isOpen, setIsOpen] = useState(false);


  const handleFileChange = (event) => {
    setSelectedFiles(prevFiles => [...prevFiles, ...Array.from(event.target.files)]);
  };

  const textAreaRef = useRef(null);

  const copyToClipboard = () => {
    if (textAreaRef.current) {
      textAreaRef.current.select();
      document.execCommand('copy');
      toast.success("Link copied successfully")}}

  const checkfilesize = async () => {
    for (const file of selectedFiles) {
      if (file.size > 104857600) {
        return false;
      }
    }
    return true;
  };
  
  const zipfile = async () => {
    const fileSizeCheck = await checkfilesize();
  if (!fileSizeCheck) {
    toast.error("One or more files are too large! Please select a file with size less than 100MB");
    setIsOpen(true)
    return;
  }else{
    return new Promise((resolve, reject) => {
      let zip = new JSZip();
      toast.info("Zipping files....");
      
      selectedFiles.forEach((file) => {
        zip.file(file.name, file);
      });
  
      zip.generateAsync({ type: "blob" }).then((content) => {
        resolve(content);
      }).catch((error) => {
        reject(error);
      });
    });
  }};

  const handleDrop = (event) => {
    event.preventDefault();
    setSelectedFiles(prevFiles => [...prevFiles, ...Array.from(event.dataTransfer.files)]);
  };

  const handleRemoveFile = (index) => {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);
  };

  const handleUpload = async () => {
  if(!selectedFiles) return alert( "No files selected" );
    if (selectedFiles.length > 0) {
      setprog(true)
      setProgress(0)
      try{
          const zipBlob = await zipfile();
          const fd = new  FormData();
          selectedFiles.forEach(file => {
            const btn = document.getElementById('uploader');
            btn.disabled= true;
            btn.style.backgroundColor="grey";
            fd.append( 'file', zipBlob,'files.zip' ) ;
          });
          toast.info("uploading")
          await axios.post('https://tmpfiles.org/api/v1/upload/',fd,{
        onUploadProgress:(progressevent)=>{console.log(progressevent.progress*100)
        const progresscom =  Math.round((progressevent.loaded / progressevent.total) * 100);
        setProgress(progresscom)
      }
    
      }).then((r)=>{
        toast.success("File Uploaded") 
        console.log("Sucessfully uploaded",r.data.data.url);
        if(r.data.status==="success"){
          console.log("Redirecting");
          let url = r.data.data.url;
          const parts = url.split('/'); 
           const newurl = parts.slice(0, 3).join('/') + '/dl/' + parts.slice(3).join('/');
            seturl(newurl)
           console.log(newurl)
          }
        setprog(false)
        setSelectedFiles([])
      }).catch((e)=>{
        console.error("Error uploading ", e);
        setprog(false)
      })
    }
    catch(e){
      console.log(e)
      alert("An error occured while zipping the file");
    }
  }
   
  };

 const clearque = () =>{
    
    setSelectedFiles([])
    setprog(false)
 }
  return (<>
    <ModalUpgrade  isOpen={isOpen} setIsOpen={setIsOpen}/>
   <div style={{overflow:"hidden"}}>
  <div className={Styles.backgp}>
      <div className={Styles.flexContainer}>
      <Alert severity="success" color="warning" style={{fontSize:'1.3rem'}} >
       Temp share files will auto delete within 60 min enusre to use service before that !
      </Alert>
        <div className={Styles.uploaderContainer}>
          
      <div
        className={Styles.filePicker}
        onDrop={handleDrop}
        onDragOver={(event) => event.preventDefault()}
      >
        <input
          id="fileInput"
          type="file"
          onChange={handleFileChange}
          multiple
          style={{ display: 'none' }}
        />
        <p style={{fontSize:"1.4rem",color:"#ffff00",fontFamily:"fantasy",userSelect:"none"}}>
          Drag and drop files here or{' '}
          <label htmlFor="fileInput" className={Styles.fileInputLabel}>
            click to select files
          </label>
        </p>
      </div>
      
      {showprog && 
    <progress value={progress} max="100" className={Styles.progress} />
      }
      {selectedFiles.length > 0 && (
        <div className={Styles.fileList}>
          <h3>Selected Files:</h3>
          <ul>
            {selectedFiles.map((file, index) => (
              <li key={index}>
                {file.name}
                <button onClick={() => handleRemoveFile(index)}>Remove</button>
              </li>
            ))}
          </ul>
          <button id='uploader' className={Styles.uploadButton} onClick={handleUpload}>
        Upload
      </button>

      <button className={Styles.clearButton}  onClick={clearque}>
        Clear All
      </button>
        </div>
        
      )}   
      {url !== '' ? (
        <div className={Styles.resultBox}>
          <textarea className={Styles.textarea} readOnly value={url} ref={textAreaRef}></textarea>
          <button className={Styles.button} onClick={copyToClipboard}>Copy Url</button>
        </div>
      ) : null}
    </div>
   </div>
    </div>
    </div>
    </>
  );
}

export default UploaderD;
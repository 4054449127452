import React from 'react';
const ShareLinkPopup = ({ link, onClose }) => {
  const copyToClipboard = () => {
    navigator.clipboard.writeText(link);
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-8 shadow-lg">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">Share Link</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-800">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <input
          type="text"
          value={link}
          readOnly
          className="w-full p-2 mb-4 border border-gray-300 rounded"
        />
        <button
          onClick={copyToClipboard}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Copy Link
        </button>
      </div>
    </div>
  );
};

export default ShareLinkPopup;
import React,{ useState, useEffect } from 'react';
import Tabs from './Tabs';

function UsrServDashboard() {

  return (
    
      <Tabs/>
  )
}
export default UsrServDashboard;


import React, { useEffect, useState } from "react";
import "./ok.css";
import { toast, Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

import { urlforback } from "../url";

  const url = `${urlforback}api` ;
  const Signupg = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [repassword, setRepassword] = useState("");
  const [passVisibility, setPassVisibility] = useState(false);
  const [username, setUsername] = useState("");



  const handleForgotPassword = async (e) => {
    e.preventDefault();
    toast.success("Sending reset link to your email")

    console.log(email);
    axios
      .get(`${url}/sendrestlink`, {
        params: { email: email },
      })
      .then((res) => {
        console.log(res.data.message);
      })
      .catch((err) =>
        toast.error(err.response.data.message, {
          transition: Flip,
          theme: "dark",
        })
      );
  };

  const handleSignin = async (e) => {
    e.preventDefault();
    if (!email || !password) return toast.error("All fields are required!");
      axios
        .post(`${url}/Login`, {
          username: username,
          email: email,
          password: password,})
        .then((res) => {
          console.log("i am response", res)
          if (res.status === 200) {
                toast.success("USER LOGGEDIN!", {
                  position: "top-center",
                  autoClose: 2000,
                  hideProgressBar: false,
                  theme: "dark",
                  transition: Flip,
                });
             
             window.localStorage.setItem('token',res.data.token)
             window.localStorage.setItem('IsLogged',true)
             window.location.href="/"
             console.log("touched")
          }
        })
        .catch((Err) => {
          console.log("error data", Err);
          if(Err.response.status === 426){axios.get(`${url}/sendverificationlink?email=${email}`,{
            email:email
          })
        .then(()=>{toast.warn("Please  check your Email for verification link.", {position: "bottom-right"});})
        .catch((e)=>{console.log(e)})
      } 
          const errcont = Err.response ? Err.response.data : Err.message;
          toast(errcont, {
            theme: "dark",
            type: "error",
          });
          console.log(errcont);
        });
  
  };

  const showPass = () => {
    setPassVisibility(!passVisibility);
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    const emailv = email;
    const passwordv = password;
    const usern = username;

    axios
      .post(`${url}/Signup`, {
        username: usern,
        email: emailv,
        password: passwordv,
      })
      .then((res) => {
        console.log(res);
        if (res.status === 201) {
          toast.success("successful ");
          toast.info("Please check email", { delay: 2000 });
        } else {
          toast.error("failed");
        }
      })
      .catch((Err) => {
        console.log("error data", Err);
        const errcont = Err.response ? Err.response.data : Err.message;
        toast(errcont, {
          theme: "dark",
          type: "error",
        });
        console.log(errcont);
      });

  };

  return (
    <div className="body">
      <div id="baap"> </div>
      <div className="container">
        <div className="Rotator">
          <div className="main">
            <h1 className="h1sg">SIGNUP</h1>
            <form style={{ userSelect: "none" }} className="form">
              <label htmlFor="email" className="labelsg">
                EMAIL
              </label>
              <input
                className="inputsg"
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                id="email"
                name="email"
                placeholder="example123@gmail.com"
                required
              />

              <label className="labelsg" htmlFor="username">
                username
              </label>
              <input
                type="text"
                onChange={(e) => setUsername(e.target.value)}
                value={username}
                id="username"
                name="username"
                className="inputsg"
                placeholder="Username"
                required
              />
              <label className="labelsg" htmlFor="password">
                Password:
              </label>
              <input
                type={passVisibility ? "text" : "password"}
                className="inputsg"
                id="password"
                name="password"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                autoComplete="off"
                placeholder="Enter your Password"
                required
              />
   

              <label className="labelsg" htmlFor="repassword">
                Re-enter Password:
              </label>
              <input
                type={passVisibility ? "text" : "password"}
                className="inputsg"
                name="repassword"
                value={repassword}
                onChange={(e) => setRepassword(e.target.value)}
                placeholder="Re-enter Password"
                required
              />
              <span className="spansg">
                <a
                  href="#"
                  id="turnr"
                  onClick={(e) => {
                    e.preventDefault();
                    document.querySelector(".Rotator").style.transform =
                      "rotateY(-180deg)";
                  }}
                >
                  Already member?
                </a>
              </span>
              {password !== repassword ? (
                <p
                  style={{
                    color: "#F34032",
                    fontSize: "2rem",
                    backgroundColor: "#61a278",
                  }}
                >
                  Passwords do not match
                </p>
              ) : (
                <div className="submit">
                  <button id="signsub" onClick={handleSignup}>
                    Signup
                  </button>
                </div>
              )}
            </form>
          </div>
          <div className="main2">
            <h1 className="h1sg">Login</h1>
            <form style={{ userSelect: "none" }} className="form">
              <label className="labelsg" htmlFor="email">
                EMAIL
              </label>
              <input
                className="inputsg"
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                id="email"
                name="email"
                placeholder="example123@gmail.com"
                required
              />
              <label className="labelsg" htmlFor="password">
                Password:
              </label>

              <input
                type={passVisibility ? "text" : "password"}
                className="inputsg"
                id="password"
                name="password"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                autoComplete="off"
                placeholder="Enter your Password"
                required
                style={{ flexGrow: 1 }}
              />

              <span
                className="spansg"
                style={{ display: "flex", justifyContent: "space-between" }}>
                {email && (
                  <a href="#" id="fp" onClick={handleForgotPassword}>
                    Forgot Password?
                  </a>
                )}
                <a
                  href="#"
                  id="turnl"
                  onClick={(e) => {
                    e.preventDefault();
                    document.querySelector(".Rotator").style.transform =
                      "rotateY(0deg)";
                  }}
                >
                  Want to Join Family?
                </a>
              </span>
              <div className="submit">
                <button id="signsub" onClick={handleSignin}>
                  SignIn
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Signupg;

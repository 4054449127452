import React from 'react';
import ShareLinkPopup from './ShareLinkPopup';




const FileList = ({ files, fileurl, generateurl, onDeleteFile }) => {
  return (
    <ul className="file-list list-none p-0 m-0">
      {files.map((file, index) => (
        <li key={index} className="file-item bg-white rounded shadow-md p-4 mb-4 flex justify-between items-center">
          <div>
            <p className="text-base font-medium">{file.fileName}</p>
            <p className="text-sm text-gray-500">
              Last Modified: {file.lastModified.split('T')[0].toString()}
            </p>
          </div>
          <div className="flex gap-4">
            <button
              onClick={() => generateurl(file)}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              Share
            </button>
            {fileurl && (
              <ShareLinkPopup
                link={fileurl}
                onClose={null}
              />
            )}
            <button
              onClick={() => onDeleteFile(file)}
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
            >
              Delete
            </button>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default FileList;
import React from 'react';

const NotFoundPage = () => {
    return (
      <div className="flex items-center justify-center h-screen overflow-hidden bg-black">
        <div className='h-full'>
          <img src="conf.png" alt="" style={{height: '100%', width: 'auto'}} />
        </div>
        <div className="text-center">
          <div className="relative mb-4">
            <h1 className="text-9xl font-black text-transparent bg-gradient-to-r from-purple-600 to-red-600 bg-clip-text">
              404
            </h1>
          </div>
          <h2 className="text-3xl font-bold text-white uppercase mb-6">
            File Not Found
          </h2>
          <p className="text-lg leading-loose text-gray-400"> Maybe File Expired or you got scammed!</p>
          <a
            href="/"
            className="inline-block bg-transparent border border-red-600 text-red-600 font-bold uppercase tracking-wider py-2 px-8 hover:bg-red-600 hover:text-white transition-colors duration-200">
      Back to Home 
          </a>
          <a
            href="/demo"
            className="inline-block bg-transparent border border-red-600 text-red-600 font-bold uppercase tracking-wider py-2 px-8 hover:bg-red-600 hover:text-white transition-colors duration-200"
          >
      Use Our Demo Service 
          </a>
        </div>
      </div>
    );
  };

export default NotFoundPage;

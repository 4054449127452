import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import axios from "axios";
import Userstoragechart from "../../components/charts/Userstoragechart";
import { toast } from "react-toastify";
import FileList from "./Filelist";
import { Link } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import UpgradeModel from "../../moadl/upgrade";
import { urlforback } from "../../url";
import ShareLinkPopup from "./ShareLinkPopup";
import Profie from "../../components/navbar/prof";

function UserDashboard() {
  const [IsOpen, setIsOpen] = useState(false);
  const [open, setOpen] = useState(true);
  const [userfiles, setUserfiles] = useState([]);
  const [fc, setFc] = useState(null);
  const [notUploaded, setNotUploaded] = useState(false);
  const [IsPremium, setIsPremium] = useState(false);
  const [FileDownload, setFileDownload] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [linkToShare, setLinkToShare] = useState('');
  
  const togglePopup = (link) => {
    setLinkToShare(link);
    setShowPopup(!showPopup);
  };


  const username = sessionStorage.getItem("user");

  const handleDeleteFile = async (file) => {
    try {
      const userId = sessionStorage.getItem("id");
      const key = `${userId}/${file.fileName}`;
      const response = await axios.post(`${urlforback}api/deletedofiles`, {
        bucket: "userstograge",
        key,
        id: userId,
      });

      console.log(response);
      if (response.status === 200) {
        toast.success("File deleted successfully!");
        setUserfiles(userfiles.filter((f) => f.fileName !== file.fileName));
      } else {
        toast.error("Failed to delete file.");
      }
    } catch (error) {
      console.error("Error deleting file:", error);
      toast.error("An error occurred while deleting the file.");
    }
  };


  const generateurl = async (file)=>{
    const userId = sessionStorage.getItem("id");
    const key = `${userId}/${file.fileName}`;
    if(!userId || !key){
      return toast.error("cant continue please relogin") ;
    }
    try {
      const response = await axios.post(`${urlforback}api/getsharelink`, {
        bucket: "userstograge",
        key,
        id: userId,
      });
      if(response.status===200) {
        console.log(response.data.temporaryLink);
        togglePopup(response.data.temporaryLink);
      }
    }
catch(e){
  console.log(e)
}

  }











  useEffect(() => {
    const id = sessionStorage.getItem("id");
    console.log(id);
    if (!id) {
      window.location.href = "/";
      return;
    }

    async function fetchData() {
      setOpen(true);
      try {
        const response = await axios.post(`${urlforback}api/listdofiles`, {
          id: sessionStorage.getItem("id"),
        });

        const response2 = await axios
          .post(`${urlforback}api/checkpremium`, {
            token: localStorage.getItem("token"),
          })
          .then((res) => {
            if (res.data) {
              setIsPremium(true);
            }
          })
          .catch((err) => setIsPremium(false));
        console.log(response2, "res2");

        if (response.status === 204) {
          setNotUploaded(true);
          return toast.info("No file uploaded yet?");
        }
        setUserfiles(response.data.file);
        setFc(response.data.file.length);
        console.log(userfiles.length);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setOpen(false);
      }
    }

    fetchData();

    return () => {};
  }, []);

  return (
    <>
      <div className=" z-50">
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
          onClick={null}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      <div className="overflow-hidden h-screen">
        <div className="flex flex-row items-center justify-stretch h-full w-full bg-gray-400 p-4">
          <div className="flex flex-col items-center justify-around text-white rounded-l-3xl h-full min-w-[20%] bg-slate-600 p-4 pr-12">
            <span className="text-4xl font-bold font-sans">Files Utils</span>
            <div className="flex flex-col gap-2 text-center"  >
              <Profie/>
              <img
                src="/Anime_Art.jpg"
                alt="profile"
                className="rounded-full h-24 w-24"
              />
              <span className="text-lg font-semibold">{username}</span>
            </div>
            <div className="flex flex-col gap-2 mt-4">
              <Link
                className="button brightness-90 hover:brightness-110"
                to="/uploadtodo"
              >
                Upload
              </Link>
              <Link
                to="/uploader"
                className="button brightness-90 hover:brightness-110"
              >
                Share
              </Link>
              <Link
                to="/p2psender"
                className="button brightness-90 hover:brightness-110"
              >
                Peer to Peer
              </Link>
            </div>
            <div className="flex items-center justify-center h-[200px] w-[180px] border-dashed border-2 rounded-xl brightness-90 hover:brightness-100">
              <div className="flex flex-col gap-2 items-center justify-center h-[90%] w-[90%] rounded-xl bg-gradient-to-r from-slate-500 to bg-slate-400 ">
                <span
                  onClick={() => {
                    window.location.href = "/uploader";
                  }}
                  className="text-lg font-semibold"
                >
                  Quick Share
                </span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-10 h-10"
                >
                  <path
                    fillRule="evenodd"
                    d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25ZM12.75 9a.75.75 0 0 0-1.5 0v2.25H9a.75.75 0 0 0 0 1.5h2.25V15a.75.75 0 0 0 1.5 0v-2.25H15a.75.75 0 0 0 0-1.5h-2.25V9Z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div className="p-4 h-[99%] max-h-screen  bg-slate-200 rounded-3xl grid grid-cols-3 gap-3 ml-[-1.875rem]">
            <div className="flex h-[90vh] items-center overflow-hidden justify-center row-span-2 col-span-2 bg-slate-50 rounded-xl">
              {notUploaded ? (
                <section className="flex w-full bg-c2  items-center align-middle justify-center flex-col h-full">
                  <div className="upload-placeholder flex flex-col justify-between h-full p-20">
                    <figure className="flex flex-col justify-center align-middle items-center">
                      <img src="search.png" alt="Upload placeholder" />
                      <figcaption className=" text-center text-teal-200 text-2xl">
                        Uploaded files will be visible here. Currently no files
                        are uploaded. Consider uploading one.
                      </figcaption>
                    </figure>
                    <button
                      onClick={() => {
                        window.location.href = "/uploadtodo";
                      }}
                      className="btn-upload text-center text-black hover:text-rose-600 text-base font-extrabold bg-red-200 p-1">
                      Upload one
                    </button>
                  </div>
                </section>
              ) : (
                <div className="no-scrollbar h-full overflow-hidden overflow-y-scroll w-4/5">
                  <FileList files={userfiles} generateurl={generateurl} fileurl={FileDownload} onDeleteFile={handleDeleteFile} />
                </div>
              )}
            </div>
                


            <div className="max-h-[50vh] h-full  rounded-xl"><Userstoragechart fn={fc}/> </div>
        
            <div  className="items-center justify-evenly  rounded-xl max-h-full overflow-y-scroll no-scrollbar h-auto">
                  {IsPremium ? (
                    <>
                      {" "}
                      <div className="flex flex-col items-center justify-center mb-4 py-8 px-4 max-h-full h-full no-scrollbar overflow-auto  rounded-lg shadow-md">
                        <h2 className="text-2xl font-semibold text-gray-800">
                          ✨ Thank you for being a shining star in our premium
                          community! ✨
                        </h2>
                        <p className="text-gray-600 mt-2 text-center">
                          Your support means the world to us, and we're thrilled to
                          have you on board to enjoy all the amazing features.
                        </p>
                      </div>
                    </>
                  ) : (
                    <>
                    <div className="flex flex-col align-middle justify-center items-center">
                      <UpgradeModel isOpen={IsOpen} setIsOpen={setIsOpen} />
                      <span className="text-center font-bold text-2xl">
                        Upgrade to premium
                      </span>
                      <span className="text-6xl">
                        <sup className="text-2xl">$ </sup>20
                      </span>
                      <ul className="list-disc">
                        <li>✔ Access to all features</li>
                        <li>✔ Temp Upload with up to 24 hours validity</li>
                        <li>✔ Temp upload Limit 500mb</li>
                      </ul>
                      <div
                        onClick={() => {
                          setIsOpen(true);
                        }}
                        className="text-center py-2 h-fit w-[80%] bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 text-white border-0 z-0 brightness-95 rounded-full mt-3 hover:cursor-pointer">
                        Buy Now!
                      </div>
                      </div> </>
                  )}{" "}
                </div>





            </div>
        
        </div>
      </div>

      {showPopup && (
        <ShareLinkPopup
          link={linkToShare}
          onClose={() => setShowPopup(false)}
        />
      )}
    </>
   )
}
export default UserDashboard;






           
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import NotFoundPage from "./Filemissing";
import ResizableComponent from "../../components/ResizableComponent/ResizableComponent";
import axios from "axios";
import UpgradeModel from "../../moadl/upgrade";
import { urlforback } from "../../url";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function DownloadTempFile() {
  const [filename, setfilename] = useState("");
  const [downloadurl, setdownloadurl] = useState("");
  const [Show404, setShow404] = useState(false);
  const [isOpen,setIsOpen] = useState(false)
  const query = useQuery();

  useEffect(() => {
    const fetchData = async () => {
      setfilename(query.get("filename"));
      try {
        const data = await axios
          .get(`${urlforback}api/checktempfile/${filename}`)
          .catch((err) => {
            if (err.response.data === "Not Found") return setShow404(true);
          });
        console.log(data);
        if (data.status === 200) {
          setdownloadurl(data.data.url);
        } else {
          setShow404(true);
        }
      } catch (err) {
        console.error(err, "in outer block");
      }
    };

    fetchData();
  }, [query]);

  if (!filename || filename === "")
    return (
      <>
     
        <div className="h-screen  w-auto flex items-center justify-center bg-rose-900">
          <div className=" flex w-auto">
            <img src="search.png" className=" h-[100vh] w-auto" alt="" />

            <h1 className="text-teal-300 font-extrabold text-5xl align-middle text-center m-auto p-12">
              Error: Still Searching for nigga who sent this link
              <br /> No file name in url
            </h1>
          </div>
        </div>
      </>
    );

  if (Show404) return <NotFoundPage />;
  else
    return (
      <div className="flex align-middle items-center justify-center w-screen max-h-screen h-screen bg-black">
        <ResizableComponent
          title="File Found"
          child={
            <div className="flex flex-col items-center justify-center h-full">
              <div className="text-white font-bold mt-8">
                <h2>
                  Download file with Name{" "}
                  <span className=" text-wrap text-green-500"> {filename}</span>
                </h2>
              </div>
              <div>
                <button
                  className=" hover:text-glow font-bold text-gray-100 text-center  py-2 px-4  mt-4"
                  onClick={() => {
                    window.open(downloadurl);
                  }}>
                  Download file now
                </button>
              </div>
            </div>
          }
        />
      </div>
    );
}

export default DownloadTempFile;
